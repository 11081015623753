.app {
  background-color: #252d4a;
  width: 520px;
  min-height: 300px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  font-size: xx-large;
  text-align: center;
  padding: 5px 5px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 7px;
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  height: 10px;
  margin-bottom: 5px;
  background-color: #d5bff7;
  border-radius: 15px;
}

#timer {
  background: rgb(230, 153, 12);
  padding: 2px;
  border: 2px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
  /* width: 97%; */
  height: 1px;
  animation: 15s timer infinite linear;
}

#time-up {
  font-size: large;
  text-align: center;
  padding: 5px 5px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* QUESTION/LEFT SECTION */
#question {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 7px 7px;
}

.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

button {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px 3px;
  margin: 5px 3px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #d5bff7;
  cursor: pointer;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

button:hover {
  background-color: #555e7d;
}

.nextbtn-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nextbtn {
  width: 240px;
  color: #ffffff;
  background-color: #545e75;
}

.nextbtn:hover {
  background-color: #ebb70d;
  color: #252d4a;
}

/* SCORE/PLAY AGAIN */
.score {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.play-again-button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  color: #ffffff;
  background-color: #545e75;
  padding: 7px 7px;
  margin: 12px 10px;
}

.play-again-button:hover {
  background-color: #ebb70d;
  color: #252d4a;
}
